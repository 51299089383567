.catalogList ul {
  margin: 0;
  padding: 0;
}

.catalogList ul li {
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 6px solid #f0eff1;
  display: inline-block;
}

.catalogList ul li .logo img {
  max-width: 150px;
  width: 100%;
}

.catalogList ul li .logo {
  width: 100%;
  padding: 20px;
  text-align: center;
}

@media screen and (min-width: 450px) {
  .catalogList ul li {
    width: calc(50% - 10px);
  }  
}

@media screen and (min-width: 800px) {
  .catalogList ul li {
    width: calc(25% - 10px);
  }  
}
