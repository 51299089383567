@import "mithui/css/core.css";
@import "mithui/css/textInput.css";
@import "mithui/css/checkbox.css";
@import "mithui/css/select.css";

@import "./form.css";
@import "./fonts.css";
@import "./table.css";
@import "./header.css";
@import "./button.css";
@import "./listInput.css";
@import "./catalogItem.css";
@import "./catalogList.css";
@import "./respond.css";
@import "./cardedList.css";

* {
  box-sizing: border-box;
}

html {
  --end-color: #3f445b;
  --start-color: #222222;
  background-color: var(--start-color);
  min-height: 100%;
  --input-padding: 8px;
}

body {
  background-color: white;
  font-family: "Open Sans";
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

h1 {
  margin-top: 0;
}
h2 {
  font-weight: 100;
}

/*
  IMPRESSION
*/
bb-jumbotron {
  margin-top: 0;
  min-height: 100vh;
}

.has-header bb-jumbotron {
  margin-top: 100px;
  min-height: calc(100vh - 50px);
}

bb-jumbotron ul li {
  display: block;
  margin: 0 30px 0 30px;
}

bb-jumbotron {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

bb-jumbotron > div {
  color: white;
  margin-top: -15%;
}

bb-jumbotron p {
  margin-left: 25%;
  margin-right: 25%;
  font-size: 120%;
}

bb-jumbotron ul {
  list-style: none;
  margin: 0;
  margin-top: 100px;
  padding: 0;
}

bb-jumbotron ul li {
  display: block;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.1);
  padding-top: 20px;
  border-radius: 10px;
  margin-right: 30px;
  margin-bottom: 30px;
}

@media (min-width: 500px) {
  bb-jumbotron ul li {
    display: inline-block;
    vertical-align: top;
    margin: 15px;
    width: 200px;
  }
}

@media (min-width: 930px) {
  bb-jumbotron {
    margin-top: 0;
  }
}

.wow-full {
  min-height: 100vh;
}
.wow {
  background: var(--start-color);
  background: linear-gradient(90deg, var(--start-color) 0%, var(--end-color) 100%);
}

.wow .home-tutorial h1 {
  font-weight: 100;
  font-size: 170%;
}

.wow .home-intro a,
.wow .home-tutorial a {
  background-color: #4f0b60;
  text-decoration: none;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  font-weight: 100;
  display: block;
  text-align: center;
  border-radius: 30px;
}

bb-jumbotron h1 {
  padding: 0;
  margin: 0;
  font-weight: 100;
  font-size: 230%;
}

/*
  TUTORIAL
*/
.tutorial {
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
}

.tutorial .home-tutorial {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #4f0b60;
  margin-bottom: 30px;
  padding: 10px;
  flex: 0 0 auto;
}

/*
  LOGIN
*/
.login {
  margin: auto;
  background-color: white;
  max-width: 500px;
  padding: 25px;
}

.login button {
  width: 100%;
}


/*
  GRID
*/
.faded {
  opacity: 0.5;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 30px 20px 100px 20px;
  max-width: 970px;
  min-height: calc(100vh - 294px);
}

.grow {
  flex-grow: 1;
}

.right {
  text-align: right;
}

.break-column {
  width: 0;
  height: auto;
  flex-basis: 100%;
  height: 0;
  margin: 0;
}

/*
  FOOTER
*/
.copyright {
  color: rgb(99, 99, 99);
  margin-top: 50px;
  text-align: center;
}

footer {
  padding: 20px;
  background-color: rgb(29, 29, 29);
  color: white;
}

  /* START FOOTER > GRID */
  footer > div.grid {
    display: grid;
    margin: auto;
    max-width: 900px;
    grid-template-columns:  50% 50%;
  }

  footer > div.grid div:first-child {
    grid-column: 1 / -1;
  }

  footer > div > div {
    white-space: nowrap;
    margin-right: 80px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 850px) {
    footer > div.grid {
      grid-template-columns: [first] auto repeat(4, 4fr);
    }

    footer > div.grid div:first-child {
      grid-column: inherit;
    }
  }
  /* END FOOTER > GRID */

footer ul li a:hover {
  text-decoration: underline;
}
footer ul li a {
  display: inline-block;
  color: rgb(201, 201, 201);
  text-decoration: none;
}
footer ul li {
  padding: 0;
  margin: 0;
}
footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

footer .copyright a {
  color: inherit;
}

footer .logo {
  display: none;
  margin: 50px;
  opacity: 0.03;
  filter: grayscale(100%);
  transition: opacity 0.5s ease;
}

footer:hover .logo {
  opacity: 0.1;
  transition: none;
}

footer > div:first-child img {
  filter: grayscale(100%);
  transition: filter 1s ease;
}

footer:hover > div:first-child img {
  filter: grayscale(0%);
  transition: none;
}

/*
  FORMS
*/
.form-error {
  border: 2px solid rgb(129, 21, 21);
  font-weight: 400;
  color: rgb(129, 21, 21);
  padding: 10px;
}

.form-field-error {
  background-color: #f8efef;
  background-image: url(../img/wrong.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 8px 9px;
  padding: 5px;
  padding-left: 30px;
  color: #811514;
  font-weight: 400;
}

.form-field {
  margin-top: 20px;
}

.form-field label:after {
  content: ':';
}
.form-field label {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.form-field input:disabled {
  color: grey;
}

.form-field:hover input:not(:disabled),
.form-field input:focus,
.form-field input:active {
  outline: none;
  border: 2px solid black;
  border-radius: 0;
}
.form-field input {
  display: block;
  padding: 10px;
  width: 100%;
  border: 2px solid rgb(218, 218, 218);
  font-size: 100%;
}

.home-boxes img {
  width: 100px;
}

.home-boxes li > *:not(:last-child) {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.launch-button {
  margin-top: 50px;
}

.launch-button a {
  background-color: white;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  color: black;
}