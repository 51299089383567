header .logo {
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
  padding-left: 10px;
  margin-right: 10px;
}

header > div:before {
  content: '\2630';
  position: absolute;
  top: 5px;
  right: 10px;
  color: white;
  font-size: 28px;
  cursor: pointer;
}

nav {
  display: none;
}

header:focus-within nav,
header:focus nav {
  display: initial;
}

header .logo a {
  color: white;
  text-decoration: none;
}

header {
  background: var(--start-color);
  background: linear-gradient(90deg, var(--start-color) 0%, var(--end-color) 100%);
}

header > div {
  outline: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 40px;
  padding-top: 3px;
  padding-bottom: 5px;
  white-space: nowrap;
  align-items: stretch;
}

header > div > * {
  width: 100%;
}

.logo img {
  height: 35px;
  vertical-align: middle;
}

nav a.active {
  background-color: rgba(0, 0, 0, 0.30);
}
nav a:hover:not(.active) {
  background-color: rgba(0, 0, 0, 0.20);
}
nav a {
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
}
