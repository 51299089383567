catalog-item-header {
  display: block;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid rgb(228, 228, 228);
}

catalog-item-header img {
  float: left;
  height: 40px;
  margin-right: 10px;
}

catalog-item-header h1 {
  margin: 0 0 10px 0;
}

catalog-item-header p {
  margin: 0 0 20px 0;
}
