mui-list-item {
  display: block;
  background-color: #f2f2f2;
}

mui-list-item > .form-group {
  margin: 0;
  border-bottom: 2px solid #555555;
}

mui-list-item details summary {
  cursor: pointer;
}

mui-list-item details > *:nth-child(2) {
  padding: 10px;
}

mui-list-insert a {
  display: block;
  border: 2px solid rgb(231, 231, 231);
  padding: 10px;
  text-align: center;
}

.list-header {
  background-color: #706b78;
  color: white;
  padding: 6px 6px 6px 10px;
  font-size: 18px;
}

