form input,
form select {
  border: 2px solid rgb(154 147 157);
}

form button {
  border: 2px solid transparent;
}

.form-group {
  margin-bottom: 10px;
}

form label {
  display: inline-block;
  margin-bottom: 5px;
}

form label:after {
  content: ':';
}

.nested-group {
  padding: 10px;
  background-color: gainsboro;
  border: 2px solid #858585;
}

mui-form mui-checkbox label {
  margin-top: 1px;
}