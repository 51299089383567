.cardedList > ul {
  margin: 0;
  padding: 0;
}

.cardedList > ul > li:hover {
  background-color: #f0eff1;
}

.cardedList > ul > li {
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 6px solid #f0eff1;
  display: flex;
  flex-wrap: wrap;
}

.cardedList > ul > li > * {
  margin-bottom: 10px;
}

.cardedList > ul > li > *:first-child {
  width: 100%;
}

.cardedList > ul > li > div > div.title a {
  text-decoration: none;
  color: black;
}
.cardedList > ul > li > div > div.title {
  font-weight: bold;
  font-size: 20px;
}

.statistic .title {
  font-weight: bold;
}

.statistic {
  text-align: center;
  padding: 8px;
  background-color: #f0eff1;
}

.cardedList > ul > li > div:not(:last-child) {
  margin-right: 10px;
}

@media (min-width: 600px) {
  .cardedList > ul > li > *:first-child {
    width: initial;
    flex-grow: 1;
  }

  .cardedList > ul > li > * {
    margin-bottom: 0;
  }
}