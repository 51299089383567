/*
  BUTTON
*/
button:disabled,
button.loading {
  color: rgba(255, 255, 255, 0.4);
}
button.loading {
  background-image: url(../img/loading.svg);
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: right 8px center;
}

button:not(:disabled):hover {
  cursor: pointer;
}
button,
.button {
  display: inline-block;
  padding: 10px;
  font-size: 100%;
  text-decoration: none;
  color: black;
  background-color: white;
  border-radius: 2px;
  padding: 5px 10px;
  border: 1px solid rgb(224, 224, 224);
}

button:not(:disabled).primary:hover,
.button:not(:disabled).primary:hover {
  border-color: #9bc48e;
  background-color: #daf1d4;
  color: green;
}
.primary {
  border-color: #5d994b;
  background-color: #5d994b;
  color: white;
}
.button-small {
  padding: 5px;
}

.button {
  margin-right: 5px;
  margin-bottom: 5px;
  /* font-weight: bold; */
}
