@media (min-width: 600px) {
  header > div > *:nth-child(2) {
    flex-grow: 1;
  }

  nav {
    display: initial;
  }

  header > div:before {
    content: '';
  }

  nav a {
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 8px 10px 8px 10px;
  }

  header > div {
    align-items: center;
    flex-direction: row;
    max-width: 970px;
    margin: auto;
  }

  header > div > * {
    width: initial;
  }
}
