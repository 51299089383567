table {
  width: 100%;
  border-collapse: collapse;
}

table th {
  text-align: left;
  padding: 5px;
  border: 1px solid #706b78;
  background-color: #706b78;
  color: white;
}

table td {
  text-align: left;
  border: 1px solid #706b78;
  background-color: white;
}

table td > select {
  display: inline-block;
  background-color: white;
  width: 100%;
  font-size: 100%;
  border: 0;
  padding: 5px;
}

table td > input {
  display: inline-block;
  width: 100%;
  font-size: 100%;
  border: 0;
  padding: 5px;
}